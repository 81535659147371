var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('loader',{attrs:{"is-loading":_vm.offersLoading,"color":"primary","margin":0}},[_c('v-col',{staticClass:"offers-col"},[_c('v-row',{staticClass:"form-header"},[_c('h5',{staticClass:"section-title"},[_vm._v(_vm._s(_vm.$t('book.form.offer.taxiCompanyLabel')))])]),_c('v-row',[_c('v-select',{staticClass:"offer-selector show-border",attrs:{"items":_vm.offers,"color":"default","placeholder":_vm.$t('book.form.selectFleet'),"append-icon":"mdi-chevron-down","item-value":"fleetId","disabled":_vm.isEditMode,"menu-props":{ nudgeBottom: 50 },"attach":"","text":"","light":"","flat":"","solo":"","hide-details":"auto"},on:{"change":_vm.selectFleet},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.properties.companyName)+" ")]}},{key:"item",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.properties.companyName)+" ")]}}]),model:{value:(_vm.fleetSelected),callback:function ($$v) {_vm.fleetSelected=$$v},expression:"fleetSelected"}})],1),_c('v-row',{staticClass:"form-header without-padding mt-6"},[_c('h5',{staticClass:"section-title",class:{ 'mt-6': _vm.availableOffers.length > 1 }},[_vm._v(_vm._s(_vm.$t('book.form.offer.label')))])]),_c('v-row',[_c('v-select',{staticClass:"offer-selector show-border",attrs:{"color":"default","items":_vm.availableOffers,"append-icon":"mdi-chevron-down","placeholder":_vm.$t('book.form.selectVehicle'),"menu-props":{ nudgeBottom: 50 },"item-value":"id","attach":"","text":"","light":"","flat":"","solo":"","hide-details":"auto"},on:{"change":_vm.selectOffer},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("fallbackToString")(item.name,'book.form.offer.name'))+" ")]}},{key:"item",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("fallbackToString")(item.name,'book.form.offer.name'))+" ")]}}]),model:{value:(_vm.offerSelected),callback:function ($$v) {_vm.offerSelected=$$v},expression:"offerSelected"}},[_c('template',{slot:"prepend-inner"},[_c('icabbi-car')],1)],2)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }