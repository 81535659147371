<template>
  <loader :is-loading="offersLoading" color="primary" :margin="0">
    <v-col class="offers-col">
      <v-row class="form-header">
        <h5 class="section-title">{{ $t('book.form.offer.taxiCompanyLabel') }}</h5>
      </v-row>
      <v-row>
        <v-select
          :items="offers"
          v-model="fleetSelected"
          color="default"
          class="offer-selector show-border"
          :placeholder="$t('book.form.selectFleet')"
          append-icon="mdi-chevron-down"
          item-value="fleetId"
          :disabled="isEditMode"
          :menu-props="{ nudgeBottom: 50 }"
          attach
          text
          light
          flat
          solo
          hide-details="auto"
          @change="selectFleet"
        >
          <template slot='selection' slot-scope='{ item }'>
            {{ item.properties.companyName }}
          </template>
          <template slot='item' slot-scope='{ item }'>
            {{ item.properties.companyName }}
          </template>
        </v-select>
      </v-row>
      <v-row class="form-header without-padding mt-6">
        <h5 class="section-title" :class="{ 'mt-6': availableOffers.length > 1 }">{{ $t('book.form.offer.label') }}</h5>
      </v-row>
      <v-row>
        <v-select
          color="default"
          class="offer-selector show-border"
          :items="availableOffers"
          append-icon="mdi-chevron-down"
          :placeholder="$t('book.form.selectVehicle')"
          :menu-props="{ nudgeBottom: 50 }"
          item-value="id"
          attach
          text
          light
          flat
          solo
          hide-details="auto"
          v-model="offerSelected"
          @change="selectOffer"
        >
          <template slot='prepend-inner'>
            <icabbi-car />
          </template>
          <template slot='selection' slot-scope='{ item }'>
            {{ item.name | fallbackToString('book.form.offer.name') }}
          </template>
          <template slot='item' slot-scope='{ item }'>
            {{ item.name | fallbackToString('book.form.offer.name') }}
          </template>
        </v-select>
      </v-row>
    </v-col>
  </loader>
</template>
<script>
import { mapGetters } from 'vuex';
import _ from 'lodash';
import { iconLoader } from '../../../../helpers/iconsLoader';

export default {
  name: 'offer-picker',
  components: {
    ...iconLoader.booking,
  },
  props: {
    offer: Object,
    isEditMode: Boolean,
  },
  data() {
    return {
      screenName: 'booking',
      offerSelected: null,
      fleetSelected: null,
      mappedOffers: {},
      availableOffers: [],
    };
  },
  watch: {
    getPickupAndDestinationCoordinates: {
      handler(val) {
        if (!val) return;

        this.callFetchOffers();
      },
    },

  },
  computed: {
    ...mapGetters({
      offers: 'offers/offers',
      offersLoading: 'offers/offersLoading',
      pickup: 'geolocation/pickup',
      destination: 'geolocation/destination',
    }),
    getPickupAndDestinationCoordinates() {
      const destinationCoordinates = _.get(this.destination, 'coordinates');
      const pickupCoordinates = _.get(this.pickup, 'coordinates');

      if (destinationCoordinates && pickupCoordinates) {
        return {
          destinationLatitude: destinationCoordinates.latitude,
          destinationLongitude: destinationCoordinates.longitude,
          pickupLatitude: pickupCoordinates.latitude,
          pickupLongitude: pickupCoordinates.longitude,
        };
      }

      return null;
    },
  },
  methods: {
    offerChanged() {
      this.selectOffer();
    },
    selectOffer(val) {
      this.$emit('input', { offerId: val, fleetId: this.fleetSelected });
    },
    selectFleet(val) {
      this.availableOffers = _.filter(this.offers, { fleetId: val });

      if (this.offer) {
        this.offerSelected = this.availableOffers.find(el => el.oldOfferId === this.offer.id).id;
      } else {
        this.offerSelected = _.get(this.availableOffers, '0.id');
      }

      this.$emit('input', { offerId: this.offerSelected, fleetId: val });
    },
    async callFetchOffers() {
      if (this.offerSelected) return;

      const options = {
        ...this.getPickupAndDestinationCoordinates,
        lang: this.$i18n.locale,
      };

      await this.$store.dispatch('offers/getOffersList', options);

      if (this.offers.length > 0) {
        this.fleetSelected = this.offer ? this.offer.fleetId : _.get(this.offers, '0.fleetId');

        this.selectFleet(this.fleetSelected);
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.form-header {
    &.with-padding-large {
      padding-top: 23px;
    }
    &.with-padding-medium {
      padding-top: 15px;
    }
    &.without-padding {
      padding-top: 0;
    }
  }


.form-label {
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;

  display: flex;
  align-items: center;
  text-transform: uppercase;
  color: #000000;
  margin-bottom: 8px !important;
}
</style>

<style lang="scss">
  .offer-type {
    background: #FFFFFF;
    border: 1px solid #E4E4E4;
    box-sizing: border-box;
    border-radius: 5px;
    width: 73px;
    height: 59px;
    position: relative;
    margin-bottom: 10px;
  }
  .offer-type:hover, .offer-type--selected {
    cursor: pointer;
    border: 2px solid #148EFF;
  }

  .offer-selector {
    padding: 10px;

    .v-list {
      padding: 0px;
    }

    .v-input__control {
      .v-input__slot {
        .v-select__slot {
          .v-select__selections {
            margin-left: 5px;
            margin-bottom: -5px !important;
          }
        }
      }
    }
  }


.section-title {
  text-transform: uppercase;
  letter-spacing: .1em;
  font-size: 10px;
  font-weight: 400;
  color: #888;
  padding-bottom: 10px;
}

  .offers-col {
    padding: 12px 12px 0 12px;
  }

  @media only screen and (max-width: 960px) {
    .offer-type {
      width: 60px;
    }
  }
</style>
