/* eslint-disable global-require */
import Vue from 'vue';

import * as GmapVue from 'gmap-vue';
import Vuelidate from 'vuelidate';
import Components from '@icabbi/vue-components';
import vueHeadful from 'vue-headful';
import VueGeolocation from 'vue-browser-geolocation';
import cookieconsent from 'vue-cookieconsent-component';
import VueSelect from 'vue-cool-select';
import VuelidateErrorExtractor, { templates } from 'vuelidate-error-extractor';
import Notifications from 'vue-notification';

import { Icon } from 'leaflet';
import vuetify from './plugins/vuetify';
import App from './App.vue';
import i18n from './i18nInstance';
import router from './router';
import store from './store';
import AlertError from './views/shared/errors/Alert.vue';
import 'leaflet/dist/leaflet.css';
import '@icabbi/vue-components/dist/vue-components.css';
import i18nAttributes from './validation/i18nAttributes';
import HtmlHead from './views/shared/head/htmlHead.vue';

// eslint-disable-next-line import/extensions
import { createFirebaseMiddlewareRouteEvent } from './events/firebase.js';

// eslint-disable-next-line import/order
import Multiselect from 'vue-multiselect';
// eslint-disable-next-line import/order
import VueTelInput from 'vue-tel-input';

// Styles
import 'vue-multiselect/dist/vue-multiselect.min.css';
import 'vue-tel-input/dist/css/sprite.css'; // Flags styles
import 'vue-tel-input/dist/css/component.css'; // Component styles
import './assets/styles/main.scss';

import fallbackToStringFilter from './filters/fallbackToStringFilter';
import truncateFilter from './filters/truncateFilter';

// this part resolve an issue where the markers would not appear
delete Icon.Default.prototype._getIconUrl;

Icon.Default.mergeOptions({
  iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
  iconUrl: require('leaflet/dist/images/marker-icon.png'),
  shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
});

Vue.use(GmapVue, { load: { key: process.env.VUE_APP_GOOGLE_MAP_KEY } });
Vue.use(Components);
Vue.use(VueTelInput);
Vue.use(VueGeolocation);
Vue.use(Vuelidate);
Vue.use(VuelidateErrorExtractor, {
  i18n: 'validations',
  i18nAttributes,
});
Vue.use(Notifications);

Vue.component('multiselect', Multiselect);
Vue.component('form-wrapper', templates.FormWrapper);
Vue.component('vue-headful', vueHeadful);

i18n.locale = store.getters['language/selectedLanguage'];

Vue.use(VueSelect, {
  theme: 'material-design',
});

Vue.component('alert-error', AlertError);
Vue.component('cookie-consent', cookieconsent);
Vue.component('html-head', HtmlHead);

Vue.filter('fallbackToString', fallbackToStringFilter);
Vue.filter('truncate', truncateFilter);


router.beforeEach(createFirebaseMiddlewareRouteEvent(store));

new Vue({
  vuetify,
  router,
  store,
  i18n,
  render: h => h(App),
}).$mount('#app');
