import { minLength, maxLength, required, requiredIf } from 'vuelidate/lib/validators';
import { isBeforeLimitTimeValues, isAfterPickupTime, isNotInThePast, isValidString } from './validationMethods';

export default {
  booking: {
    origin: {
      address: {
        required,
        minLength: minLength(3),
        maxLength: maxLength(1000),
      },
    },
    destination: {
      address: {
        required,
        minLength: minLength(3),
        maxLength: maxLength(1000),
      },
    },
    bookingProperties: {
      flightInfo: {
        number: {
          required: requiredIf(val => val.isMandatory),
          minLength: minLength(3),
          maxLength: maxLength(6),
        },
      },
    },
    waypoints: {
      $each: {
        required: requiredIf(val => val.description),
        minLength: minLength(3),
        maxLength: maxLength(1000),
      },
    },
    pickupTime: {
      isNotInThePast,
      isBeforeLimitTimeValues: isBeforeLimitTimeValues(30, 'days'),
    },
    returnTime: {
      isNotInThePast,
      isBeforeLimitTimeValues: isBeforeLimitTimeValues(30, 'days'),
      isAfterPickupTime,
    },
    userInfo: {
      givenName: {
        minLength: minLength(1),
        maxLength: maxLength(100),
        isValidString,
      },
      familyName: {
        minLength: minLength(1),
        maxLength: maxLength(100),
        isValidString,
      },
      phone: {
        minLength: minLength(10),
        maxLength: maxLength(16),
      },
    },
    notes: {
      minLength: minLength(3),
      maxLength: maxLength(300),
    },
  },
};
