<template>
  <div>
    <v-dialog v-model="dialog" width="500" persistent transition="slide-x-reverse-transition">
      <v-card>
        <v-card-text class="pt-6">
          <h3 class="black--text">{{ $t("book.form.returnBooking") }}</h3>
        </v-card-text>
        <v-card-text>
          <validated-component
            name="pickupTime"
            :classes="['time-input-error']"
            v-model="pickupTime"
            :validator="$v.pickupTime"
            hideDetails="auto"
          >
            <pickup-time
              :minimumDate="minimumDate"
              :pickupTime="selectedPickupTime"
              v-on:pickupTimeUpdated="updatePickupTime"
              :return="true"
            ></pickup-time>
          </validated-component>

          <v-text-field
            v-if="isShowReturnFlightNumber"
            v-model="returnFlightNumber"
            id="flightNumber"
            :class="[$v.returnFlightNumber.$invalid ? 'error-border' : '']"
            :placeholder="$t('onboarding.form.flightNumber')"
            class="mt-3 flightNumberField"
            hide-details
            outlined
          >
            <template v-slot:append>
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-fade-transition>
                    <v-icon
                      v-if="$v.returnFlightNumber.$invalid"
                      class="validation-icon"
                      v-bind="attrs"
                      v-on="on"
                      small
                    >
                      fa-light fa-circle-exclamation
                    </v-icon>
                  </v-fade-transition>
                </template>
                <div class="d-flex flex-column">
                  <span v-if="!$v.returnFlightNumber.required">
                    {{
                      $t("validations.required", {
                        attribute: $t("rideTracking.summary.booking.flightNumber")
                      })
                    }}
                  </span>
                  <span v-if="!$v.returnFlightNumber.maxLength">
                    {{
                      $t("validations.maxLength", {
                        attribute: $t("rideTracking.summary.booking.flightNumber"),
                        max: 6
                      })
                    }}
                  </span>
                  <span v-if="!$v.returnFlightNumber.minLength">
                    {{
                      $t("validations.minLength", {
                        attribute: $t("rideTracking.summary.booking.flightNumber"),
                        min: 3
                      })
                    }}
                  </span>
                </div>
              </v-tooltip>
            </template>
          </v-text-field>
        </v-card-text>

        <v-card-text class="px-6 pb-4 d-flex justify-space-between">
          <v-btn class="pl-0" plain @click="cancelReturnBooking">
            {{ $t("book.form.cancel") }}
          </v-btn>
          <v-btn
            depressed
            color="primary"
            @click="handleSubmitReturnBooking"
            :disabled="$v.$invalid"
          >
            {{ $t("book.form.returnConfirm") }}
          </v-btn>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import dayjs from '@/daysjs';
import { mapGetters } from 'vuex';

import { minLength, maxLength, requiredIf } from 'vuelidate/lib/validators';
import PickupTime from '@/views/shared/inputs/PickupTime.vue';
import { isNotInThePast } from '../../../validation/validationMethods';

export default {
  name: 'BookingFormReturnBooking',
  components: {
    PickupTime,
  },
  data() {
    return {
      dialog: false,
      pickupTime: null,
      selectedPickupTime: null,
      minimumDate: null,
      isShowReturnFlightNumber: false,
      returnFlightNumber: null,
    };
  },
  computed: {
    ...mapGetters({
      bookingChannelFeatures: 'bookingChannel/features',
    }),
  },
  methods: {
    openDialog({ pickupTime, destination }) {
      if (pickupTime) {
        this.minimumDate = dayjs(pickupTime).format('YYYY-MM-DDTHH:mm:ss');
        this.selectedPickupTime = dayjs(pickupTime)
          .add(10, 'minute')
          .format('YYYY-MM-DDTHH:mm:ss');
      }

      this.isShowReturnFlightNumber = destination.locationTypes.includes('airport');
      this.selectedPickupTime = this.selectedPickupTime
        || dayjs()
          .add(10, 'minute')
          .format('YYYY-MM-DDTHH:mm:ss');
      this.minimumDate = this.minimumDate || dayjs().format('YYYY-MM-DDTHH:mm:ss');
      this.dialog = true;
    },
    updatePickupTime(pickupTime) {
      this.pickupTime = pickupTime;
    },
    handleSubmitReturnBooking() {
      this.$v.$touch();

      if (this.$v.$invalid) {
        return;
      }

      this.$emit(
        'returnBooking',
        dayjs(this.pickupTime || new Date().getTime()).format('YYYY-MM-DDTHH:mm:ss'),
        this.returnFlightNumber,
      );
      this.dialog = false;
    },
    cancelReturnBooking() {
      this.dialog = false;
      this.$emit('cancelReturnBooking');
    },
  },
  validations() {
    return {
      pickupTime: {
        isNotInThePast,
        isAfterPickupTime: (value) => {
          if (value) {
            return dayjs(value).isAfter(dayjs(this.minimumDate));
          }
          return true;
        },
      },
      returnFlightNumber: {
        required: requiredIf(this.bookingChannelFeatures.airportPickupSettings && !this.bookingChannelFeatures.airportPickupSettings.canSkip),
        minLength: minLength(3),
        maxLength: maxLength(6),
      },
    };
  },
};
</script>

<style lang="scss">
  .flightNumberField {
    background: #f8f8f8;
    border-radius: 4px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-line-pack: center;
    align-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    color: #333333;
  }

  .error-border {
    border: 1px solid #FF0000 !important;
    border-radius: 4px;

    & fieldset {
      border: none !important;
    }
  }

  .validation-icon {
    color:  #ff0000 !important;
  }
</style>
