<template>
  <loader :is-loading="userLoading" color="primary">
    <div class="user-profile-container px-3 px-md-0 mt-12 mt-md-0">
      <v-card class="user-profile-card pa-2">
        <v-card-title id="profile-title">
          <span style="width: auto;">{{ $t('userProfile.title') }}</span>
        </v-card-title>

        <v-card-text class="pb-0 email-verified-message" v-if="this.showEmailValidation && !this.emailVerified">
          <user-email-verification-popup :userProfile="userProfile" :emailVerificationSent="emailVerificationSent"></user-email-verification-popup>
        </v-card-text>

        <v-card-text class="pb-0">
          <p class="user-profile-label">{{ $t('userProfile.form.givenName') }}</p>

          <validated-component
            name="givenName"
            :classes="['user-profile-error']"
            v-model="userProfile.givenName"
            :validator="$v.userProfile.givenName"
            hideDetails="auto"
          >
            <v-text-field
              class="user-profile-field show-border"
              :readonly="readonly"
              v-model="userProfile.givenName"
              :placeholder="$t('userProfile.form.givenName')"
              text
              light
              outline
              solo
              flat
              hide-details="auto"
              no-filter
            ></v-text-field>
          </validated-component>
        </v-card-text>

        <v-card-text class="pb-0">
          <p class="user-profile-label">{{ $t('userProfile.form.familyName') }}</p>

          <validated-component
            :classes="['user-profile-error']"
            name="familyName"
            v-model="userProfile.familyName"
            :validator="$v.userProfile.familyName"
            hideDetails="auto"
          >
            <v-text-field
              class="user-profile-field show-border"
              :readonly="readonly"
              v-model="userProfile.familyName"
              :placeholder="$t('userProfile.form.familyName')"
              text
              light
              outline
              solo
              flat
              hide-details="auto"
              no-filter
            ></v-text-field>
          </validated-component>
        </v-card-text>

        <v-card-text class="pb-0">
          <p class="user-profile-label">{{ $t('userProfile.form.email') }}</p>

          <validated-component
            name="email"
            :classes="['user-profile-error']"
            v-model="userProfile.email"
            :validator="$v.userProfile.email"
            v-if="this.showEmailValidation"
            hideDetails="auto"
          >
            <v-text-field
              v-if="this.showEmailValidation"
              :readonly="readonly"
              class="user-profile-field show-border"
              v-model="userProfile.email"
              :placeholder="$t('userProfile.form.email')"
              text
              light
              outline
              solo
              flat
              hide-details="auto"
              no-filter
            ></v-text-field>
          </validated-component>
        </v-card-text>

        <v-card-text class="pb-0">
          <p class="user-profile-label">{{ $t('userProfile.form.phone') }}</p>

          <validated-component
              name="phone"
              :classes="['user-profile-error']"
              v-model="userProfile.phone"
              :validator="$v.userProfile.phone"
              hideDetails="auto"
            >
            <vue-tel-input
              :disabled="!!user.phone"
              mode="national"
              v-model="user.phone"
              :dropdownOptions="{
                showFlags: true,
                width: '200px',
                showDialCodeInList: true,
                showDialCodeInSelection: true
              }"
              class="user-profile-phone user-profile-field"
              :defaultCountry="defaultCountry"
            >
              <template v-slot:arrow-icon>
                <v-icon>mdi-chevron-up</v-icon>
              </template>
            </vue-tel-input>
          </validated-component>
        </v-card-text>

        <v-card-text class="pb-0">
          <v-divider />
        </v-card-text>

        <v-card-text class="pb-0">
          <div id="communication-language-container" v-if="!readonly">
            <p class="user-profile-label-communication">{{ $t('userProfile.form.communicationLanguage') }}</p>
            <v-select
              class="user-profile-select"
              v-model="userProfile.communicationLanguage"
              :items="acceptedLanguages"
              :menu-props="{ nudgeBottom: 40 }"
              append-icon="mdi-chevron-down"
              value="value"
              hide-details="auto"
              outlined
              dense
            />
          </div>
        </v-card-text>
        <v-card-text class="pb-0 text-center">
          <div v-if="!readonly">
           <v-btn text color="error" class="btn-delete-account" @click="showDeleteDialog = true">{{ $t(`book.form.userInformation.deleteAccount`) }}</v-btn>
          </div>
        </v-card-text>

        <v-card-actions v-if="!readonly" class="mx-2 mt-5">
          <v-btn
            class="disable-button"
            color="grey darken-1"
            text
            @click="$router.back()"
          >
            {{ $t(`book.form.userInformation.cancel.value`) }}
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            class="user-profile-submit-button"
            :disabled="disablePostUserProfile"
            @click="updateUserProfile"
            depressed
          >{{ $t('book.form.userInformation.save.value') }}</v-btn>
        </v-card-actions>
      </v-card>
    </div>
    <user-delete-dialog :showDialog="showDeleteDialog" @confirmUserDeletion="deleteUser" @cancelUserDeletion="showDeleteDialog = false"></user-delete-dialog>
  </loader>
</template>

<script>
import { mapGetters } from 'vuex';
import userProfileValidation from '../../validation/userProfileValidationData';
import UserEmailVerificationPopup from './UserEmailVerificationPopup.vue';
import UserDeleteDialog from './UserDeleteDialog.vue';

export default {
  name: 'UserProfile',
  components: {
    UserEmailVerificationPopup,
    UserDeleteDialog,
  },
  validations() {
    return userProfileValidation;
  },
  data() {
    return {
      screenName: 'user_profile',
      userProfile: {
        email: '',
        givenName: '',
        familyName: '',
        emailVerified: false,
        communicationLanguage: null,
      },
      acceptedLanguages: [
        { text: 'French', value: 'fr' },
        { text: 'English', value: 'en' },
        { text: 'Finnish', value: 'fi' },
        { text: 'Spanish', value: 'es' },
      ],
      showDeleteDialog: false,
    };
  },
  created() {
    if (!this.userLoading) {
      this.$store.dispatch('userProfile/getUser');
    }
  },
  watch: {
    user(newValue) {
      this.userProfile = {
        givenName: newValue.givenName,
        familyName: newValue.familyName,
        email: newValue.email,
        emailVerified: newValue.emailVerified,
        communicationLanguage: newValue.communicationLanguage,
      };
    },
  },
  computed: {
    ...mapGetters({
      acrValue: 'authentication/acrValue',
      user: 'userProfile/user',
      userLoading: 'userProfile/userLoading',
      emailVerificationEnabled: 'features/emailVerification',
      emailVerificationSent: 'userProfile/emailVerificationSent',
      defaultCountry: 'bookingChannel/defaultCountry',
    }),
    readonly() {
      return this.acrValue === process.env.VUE_APP_LEGACY_BUSINESS_ACR_VALUES || this.acrValue === process.env.VUE_APP_BUSINESS_ACR_VALUES;
    },
    disablePostUserProfile() {
      return this.$v.$invalid || this.userLoading;
    },
    emailExists() {
      return this.userProfile.email;
    },
    emailVerified() {
      return this.userProfile.emailVerified;
    },
    showEmailValidation() {
      return this.emailVerificationEnabled;
    },
  },
  methods: {
    async updateUserProfile() {
      await this.$store.dispatch('userProfile/updateUser', this.userProfile);
      await this.$store.dispatch('authentication/setDisplayName');
    },
    getLanguageLabel(language) {
      return this.$t(`userProfile.form.${language}`);
    },
    async deleteUser() {
      const deleted = await this.$store.dispatch('userProfile/deleteUser');
      if (!deleted) {
        this.showDeleteDialog = false;
        return;
      }
      await this.$router.push('/logout');
      this.$forceUpdate();

      this.showDeleteDialog = false;
    },
  },
};
</script>

<style lang="scss">
.user-profile-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 500px;
  min-width: 500px;
  height: 100vh;

  flex-direction: column;
  align-items: flex-start;
  padding: 25px;
  gap: 24px;
  transition: .2s;

  @media screen and (max-width: 767px) {
    width: 100% !important;
    min-width: 100%;
    padding: 0;
  }
}

.user-profile-label {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 12px;
  display: flex;
  align-items: center;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  margin-bottom: 10px;
  color: #888888;
}

.user-profile-card {
  width: 100%;
  border-radius: 8px !important;
}

.user-profile-phone {
  width: 100%;
  height: 48px;
}

.user-profile-label-communication {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-size: 15px;
  line-height: 24px;
  letter-spacing: -0.005em;
}

.user-profile-field {
  border: 1px solid #EEEEEE;
  background-color: #F8F8F8;
  border-radius: 6px;
}

.user-profile-select {
  background-color: #F8F8F8;
  border-radius: 6px;

  & .v-input__slot {
    height: 48px !important;
  }

  & .v-input__append-inner {
    margin-top: 12px !important;
  }
}

.user-profile-submit-button {
  color: #ffffff;
}

.btn-delete-account {
  text-transform: none!important;
}
</style>
